"use client";

import { useEffect } from "react";

import * as Sentry from "@sentry/nextjs";

export default function GlobalError({
	error,
	reset,
}: {
	error: Error;
	reset: () => void;
}) {
	useEffect(() => {
		// Capture error to Sentry
		// TODO is this necessary?
		Sentry.captureException(error);

		// Log the error to an error reporting service
		console.error(error);
	}, [error]);

	return (
		<html lang="en">
			<body>
				<h2>Something went wrong!</h2>
				<button onClick={() => reset()}>Try again</button>
			</body>
		</html>
	);
}
